import algoliasearch, { SearchClient, SearchIndex } from "algoliasearch";

const appId = process.env.GATSBY_ALGOLIA_APP_ID || "";
const searchKey = process.env.GATSBY_ALGOLIA_SEARCH_KEY || "";

export enum IndexType {
  INTERCOM_ARTICLE = "INTERCOM_ARTICLE",
  DOCUMENT = "DOCUMENT",
  NEWS_ARTICLE = "NEWS_ARTICLE",
  PRODUCT = "PRODUCT",
  PEOPLE = "PEOPLE",
  NEWS_VIDEO = "NEWS_VIDEO",
  GENERAL = "GENERAL",
  TOOLS = "TOOLS",
}

export const IndexTypeDisplayNames = {
  INTERCOM_ARTICLE: "Articles",
  DOCUMENT: "Documents",
  NEWS_ARTICLE: "News",
  PRODUCT: "Products",
  PEOPLE: "People",
  NEWS_VIDEO: "Videos",
  GENERAL: "Other",
  TOOLS: "Tools",
};

export enum Index {
  DEFAULT= "default",
  NEWS_AND_INSIGHTS = "news-and-insights",
}

export type HitRecord = {
  objectID: string;
  title: string;
  subtitle?: string;
  type: string;
  url: string;
  id?: string;
  createdAt?: number;
  updatedAt?: number;
  highlightedTitle: string;
}

export interface SearchResult <T extends HitRecord = HitRecord>{
  hits: T[];
  nbHits: number;
  nbPages: number;
}

export type CategorizedSearchResult = {
  hits: Map<string, HitRecord[]>;
  nbHits: number;
};

export type SearchOptions = {
  attributesToHighlight: string[];
  attributesToRetrieve: string[];
  highlightPreTag: string;
  highlightPostTag: string;
  hitsPerPage: number;
  analytics: boolean;
  page?: number;
  type?: IndexType;
  facetFilters?: string[];
}

const defaultSearchOptions: SearchOptions = {
  attributesToHighlight: ["title"],
  highlightPreTag: "<b>",
  highlightPostTag: "</b>",
  hitsPerPage: 50,
  attributesToRetrieve: ["objectID", "title", "url", "type", "createdAt", "subtitle", "tags"],
  analytics: true,
};

export interface ISearchService {
  search: (
    input: string,
    indexName: string,
    searchOptions?: Partial<SearchOptions>,
  ) => Promise<SearchResult | null>;

  categorizedSearch: (
    input: string,
    indexName: string,
    searchOptions?: Partial<SearchOptions>,
  ) => Promise<CategorizedSearchResult>;
}

export class SearchService implements ISearchService {
  public readonly client: SearchClient;

  private indexes: {
    [key: string]: SearchIndex;
  } = {};

  constructor() {
    this.client = algoliasearch(appId, searchKey);
  }

  public search = async <T extends HitRecord>(
    input: string,
    indexName = "default",
    searchOptions?: Partial<SearchOptions>,
  ): Promise<SearchResult<T>> => {
    const index = await this.getIndex(indexName);

    const type = searchOptions?.type;
    const facetFilters = type ? [`type:${type}`] : [];

    const options = {
      ...defaultSearchOptions,
      ...searchOptions,
      facetFilters: [...facetFilters, ...(searchOptions?.facetFilters ? searchOptions?.facetFilters : [])],
      attributesToRetrieve: [
        ...defaultSearchOptions.attributesToRetrieve,
        ...(searchOptions?.attributesToRetrieve ? searchOptions?.attributesToRetrieve : []),
      ],
    };

    delete options.type;

    const searchResult = await index.search<T>(input, options);

    const refinedHits = searchResult.hits.map((hitRecord) => ({
      ...hitRecord,
      // eslint-disable-next-line no-underscore-dangle
      highlightedTitle: hitRecord._highlightResult?.title?.value || hitRecord.title,
    }));

    return { ...searchResult, hits: refinedHits };
  };

  public categorizedSearch = async (
    input: string,
    indexName = "default",
    searchOptions?: Partial<SearchOptions>,
  ): Promise<CategorizedSearchResult> => {
    const searchResult = await this.search(input, indexName, searchOptions);

    const categorisedResult: CategorizedSearchResult = {
      hits: new Map<string, HitRecord[]>(),
      nbHits: searchResult.nbHits,
    };

    searchResult.hits.forEach((result) => {
      let category = IndexTypeDisplayNames[IndexType[result.type as keyof typeof IndexType]];
      if (!category) {
        category = IndexTypeDisplayNames[IndexType.GENERAL];
      }

      if (!categorisedResult.hits.has(category)) {
        categorisedResult.hits.set(category, []);
      }

      if (categorisedResult.hits.has(category)) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        categorisedResult.hits.get(category)!.push(result);
      }
    });

    return categorisedResult;
  };

  private getIndex(name: string) {
    if (!this.indexes[name]) {
      this.indexes[name] = this.client.initIndex(name);
    }

    return this.indexes[name];
  }
}
