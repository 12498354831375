exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-board-index-tsx": () => import("./../../../src/pages/board/index.tsx" /* webpackChunkName: "component---src-pages-board-index-tsx" */),
  "component---src-pages-carbon-intensity-metrics-index-tsx": () => import("./../../../src/pages/carbon-intensity-metrics/index.tsx" /* webpackChunkName: "component---src-pages-carbon-intensity-metrics-index-tsx" */),
  "component---src-pages-digital-products-terms-conditions-index-tsx": () => import("./../../../src/pages/digital-products-terms-conditions/index.tsx" /* webpackChunkName: "component---src-pages-digital-products-terms-conditions-index-tsx" */),
  "component---src-pages-fisher-funds-two-index-tsx": () => import("./../../../src/pages/fisher-funds-two/index.tsx" /* webpackChunkName: "component---src-pages-fisher-funds-two-index-tsx" */),
  "component---src-pages-fisher-funds-two-investment-options-index-tsx": () => import("./../../../src/pages/fisher-funds-two/investment-options/index.tsx" /* webpackChunkName: "component---src-pages-fisher-funds-two-investment-options-index-tsx" */),
  "component---src-pages-forms-and-documents-index-tsx": () => import("./../../../src/pages/forms-and-documents/index.tsx" /* webpackChunkName: "component---src-pages-forms-and-documents-index-tsx" */),
  "component---src-pages-funds-and-performance-index-tsx": () => import("./../../../src/pages/funds-and-performance/index.tsx" /* webpackChunkName: "component---src-pages-funds-and-performance-index-tsx" */),
  "component---src-pages-futureplan-index-tsx": () => import("./../../../src/pages/futureplan/index.tsx" /* webpackChunkName: "component---src-pages-futureplan-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-internal-join-tsx": () => import("./../../../src/pages/internal-join/[...].tsx" /* webpackChunkName: "component---src-pages-internal-join-tsx" */),
  "component---src-pages-investing-with-us-index-tsx": () => import("./../../../src/pages/investing-with-us/index.tsx" /* webpackChunkName: "component---src-pages-investing-with-us-index-tsx" */),
  "component---src-pages-investment-funds-index-tsx": () => import("./../../../src/pages/investment-funds/index.tsx" /* webpackChunkName: "component---src-pages-investment-funds-index-tsx" */),
  "component---src-pages-investment-series-index-tsx": () => import("./../../../src/pages/investment-series/index.tsx" /* webpackChunkName: "component---src-pages-investment-series-index-tsx" */),
  "component---src-pages-investment-series-investment-options-index-tsx": () => import("./../../../src/pages/investment-series/investment-options/index.tsx" /* webpackChunkName: "component---src-pages-investment-series-investment-options-index-tsx" */),
  "component---src-pages-join-tsx": () => import("./../../../src/pages/join/[...].tsx" /* webpackChunkName: "component---src-pages-join-tsx" */),
  "component---src-pages-kiwisaver-index-tsx": () => import("./../../../src/pages/kiwisaver/index.tsx" /* webpackChunkName: "component---src-pages-kiwisaver-index-tsx" */),
  "component---src-pages-kiwisaver-investment-options-index-tsx": () => import("./../../../src/pages/kiwisaver/investment-options/index.tsx" /* webpackChunkName: "component---src-pages-kiwisaver-investment-options-index-tsx" */),
  "component---src-pages-kiwisaver-plan-index-tsx": () => import("./../../../src/pages/kiwisaver-plan/index.tsx" /* webpackChunkName: "component---src-pages-kiwisaver-plan-index-tsx" */),
  "component---src-pages-kiwisaver-plan-investment-options-index-tsx": () => import("./../../../src/pages/kiwisaver-plan/investment-options/index.tsx" /* webpackChunkName: "component---src-pages-kiwisaver-plan-investment-options-index-tsx" */),
  "component---src-pages-lifesaver-index-tsx": () => import("./../../../src/pages/lifesaver/index.tsx" /* webpackChunkName: "component---src-pages-lifesaver-index-tsx" */),
  "component---src-pages-lifesaver-investment-options-index-tsx": () => import("./../../../src/pages/lifesaver/investment-options/index.tsx" /* webpackChunkName: "component---src-pages-lifesaver-investment-options-index-tsx" */),
  "component---src-pages-login-index-tsx": () => import("./../../../src/pages/login/index.tsx" /* webpackChunkName: "component---src-pages-login-index-tsx" */),
  "component---src-pages-managed-funds-index-tsx": () => import("./../../../src/pages/managed-funds/index.tsx" /* webpackChunkName: "component---src-pages-managed-funds-index-tsx" */),
  "component---src-pages-managed-funds-investment-options-index-tsx": () => import("./../../../src/pages/managed-funds/investment-options/index.tsx" /* webpackChunkName: "component---src-pages-managed-funds-investment-options-index-tsx" */),
  "component---src-pages-news-and-insights-index-tsx": () => import("./../../../src/pages/news-and-insights/index.tsx" /* webpackChunkName: "component---src-pages-news-and-insights-index-tsx" */),
  "component---src-pages-news-and-insights-list-index-tsx": () => import("./../../../src/pages/news-and-insights/list/index.tsx" /* webpackChunkName: "component---src-pages-news-and-insights-list-index-tsx" */),
  "component---src-pages-our-brand-index-tsx": () => import("./../../../src/pages/our-brand/index.tsx" /* webpackChunkName: "component---src-pages-our-brand-index-tsx" */),
  "component---src-pages-policies-and-privacy-index-tsx": () => import("./../../../src/pages/policies-and-privacy/index.tsx" /* webpackChunkName: "component---src-pages-policies-and-privacy-index-tsx" */),
  "component---src-pages-premium-service-index-tsx": () => import("./../../../src/pages/premium-service/index.tsx" /* webpackChunkName: "component---src-pages-premium-service-index-tsx" */),
  "component---src-pages-premium-service-investment-options-index-tsx": () => import("./../../../src/pages/premium-service/investment-options/index.tsx" /* webpackChunkName: "component---src-pages-premium-service-investment-options-index-tsx" */),
  "component---src-pages-privacy-policy-index-tsx": () => import("./../../../src/pages/privacy-policy/index.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-index-tsx" */),
  "component---src-pages-request-a-call-index-tsx": () => import("./../../../src/pages/request-a-call/index.tsx" /* webpackChunkName: "component---src-pages-request-a-call-index-tsx" */),
  "component---src-pages-request-an-info-pack-index-tsx": () => import("./../../../src/pages/request-an-info-pack/index.tsx" /* webpackChunkName: "component---src-pages-request-an-info-pack-index-tsx" */),
  "component---src-pages-retirement-projector-index-tsx": () => import("./../../../src/pages/retirement-projector/index.tsx" /* webpackChunkName: "component---src-pages-retirement-projector-index-tsx" */),
  "component---src-pages-spread-pricing-index-tsx": () => import("./../../../src/pages/spread-pricing/index.tsx" /* webpackChunkName: "component---src-pages-spread-pricing-index-tsx" */),
  "component---src-pages-spread-pricing-institutional-index-tsx": () => import("./../../../src/pages/spread-pricing/institutional/index.tsx" /* webpackChunkName: "component---src-pages-spread-pricing-institutional-index-tsx" */),
  "component---src-pages-support-index-tsx": () => import("./../../../src/pages/support/index.tsx" /* webpackChunkName: "component---src-pages-support-index-tsx" */),
  "component---src-pages-talk-to-an-adviser-index-tsx": () => import("./../../../src/pages/talk-to-an-adviser/index.tsx" /* webpackChunkName: "component---src-pages-talk-to-an-adviser-index-tsx" */),
  "component---src-pages-team-index-tsx": () => import("./../../../src/pages/team/index.tsx" /* webpackChunkName: "component---src-pages-team-index-tsx" */),
  "component---src-templates-fund-performance-tsx": () => import("./../../../src/templates/fund-performance.tsx" /* webpackChunkName: "component---src-templates-fund-performance-tsx" */),
  "component---src-templates-fund-tsx": () => import("./../../../src/templates/fund.tsx" /* webpackChunkName: "component---src-templates-fund-tsx" */),
  "component---src-templates-generic-pages-tsx": () => import("./../../../src/templates/generic-pages.tsx" /* webpackChunkName: "component---src-templates-generic-pages-tsx" */),
  "component---src-templates-ipq-page-tsx": () => import("./../../../src/templates/ipq-page.tsx" /* webpackChunkName: "component---src-templates-ipq-page-tsx" */),
  "component---src-templates-ipq-result-tsx": () => import("./../../../src/templates/ipq-result.tsx" /* webpackChunkName: "component---src-templates-ipq-result-tsx" */),
  "component---src-templates-news-article-pages-tsx": () => import("./../../../src/templates/news-article-pages.tsx" /* webpackChunkName: "component---src-templates-news-article-pages-tsx" */),
  "component---src-templates-product-documents-tsx": () => import("./../../../src/templates/product-documents.tsx" /* webpackChunkName: "component---src-templates-product-documents-tsx" */),
  "component---src-templates-support-article-tsx": () => import("./../../../src/templates/support-article.tsx" /* webpackChunkName: "component---src-templates-support-article-tsx" */)
}

