/* eslint-disable */
import React, {
  ReactNode, createContext, useContext, useEffect, useState,
} from "react";
import "./LiveChat.scss";

declare global {
  interface Window {
    // eslint-disable-next-line camelcase
    embedded_svc: {
      bootstrapEmbeddedService: () => void;
      init: any;
      settings: any;
    };
  }
}

const SALESFORCE_DOMAIN_NAME = process.env.GATSBY_SALESFORCE_DOMAIN_NAME;
const SALESFORCE_ORG_ID = process.env.GATSBY_SALESFORCE_ORG_ID;
const SALESFORCE_CHAT_DEPLOYMENT_ID = process.env.GATSBY_SALESFORCE_CHAT_DEPLOYMENT_ID;
const SALESFORCE_CHAT_BUTTON_ID = process.env.GATSBY_SALESFORCE_CHAT_BUTTON_ID;
const SALESFORCE_LIVE_AGENT_API_NAME = process.env.GATSBY_SALESFORCE_LIVE_AGENT_API_NAME;

export interface ILiveChatContext {
  open: () => void;
  isLoaded: boolean;
  buttonText?: string;
}

const LiveChatContext = createContext<ILiveChatContext>({
  open: () => {},
  isLoaded: false,
});

export type LiveChatContextProviderProps = {
  children: ReactNode;
};

export const LiveChatContextProvider = (
  { children }: LiveChatContextProviderProps,
) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [buttonText, setButtonText] = useState<string>();

  const helpButtonLoaded = () => {
    const helpButton = document.querySelector(".helpButton");
    if (!helpButton) {
      window.setTimeout(helpButtonLoaded, 500);
    } else {
      setIsLoaded(true);
      setButtonText(document.querySelector(".helpButton .message")?.innerHTML);

      const observer = new MutationObserver(() => {
        setButtonText(document.querySelector(".helpButton .message")?.innerHTML);
      });

      const button = document.querySelector(".helpButton .message");
      if (button) {
        observer.observe(button, { characterData: true, childList: true, attributes: false });
      }
    }
  };

  const initESW = (gslbBaseURL: string | null) => {
    // eslint-disable-next-line camelcase
    const { embedded_svc } = window;

    // Override chat transcript fields
    embedded_svc.settings.extraPrechatFormDetails = [
      {
        label: "First Name",
        transcriptFields: ["VisitorFirstName__c"],
        displayToAgent: true,
      },
      {
        label: "Last Name",
        transcriptFields: ["VisitorLastName__c"],
        displayToAgent: true,
      },
      {
        label: "Email",
        transcriptFields: ["VisitorEmailAddress__c"],
        displayToAgent: true,
      },
      {
        label: "What is your enquiry about?",
        transcriptFields: ["VisitorProduct__c"],
        displayToAgent: true,
      },
    ];

    // Match to a Contact if one exists, but don't create new ones
    embedded_svc.settings.extraPrechatInfo = [{
      entityFieldMaps: [{
        doCreate: false,
        doFind: true,
        fieldName: "LastName",
        isExactMatch: true,
        label: "Last Name",
      }, {
        doCreate: false,
        doFind: true,
        fieldName: "Email",
        isExactMatch: true,
        label: "Email",
      }],
      entityName: "Contact",
    }];

    embedded_svc.settings.displayHelpButton = true; // Or false
    embedded_svc.settings.language = "en"; // For example, enter 'en' or 'en-US'
    embedded_svc.settings.defaultMinimizedText = "Chat with us"; // (Defaults to Chat with an Expert)
    embedded_svc.settings.disabledMinimizedText = "Chat offline"; // (Defaults to Agent Offline)
    embedded_svc.settings.loadingText = "Loading..."; // (Defaults to Loading)
    embedded_svc.settings.offlineSupportMinimizedText = "Contact us"; // (Defaults to Contact Us)

    embedded_svc.settings.enabledFeatures = ["LiveAgent"];
    embedded_svc.settings.entryFeature = "LiveAgent";

    embedded_svc.init(
      `https://${SALESFORCE_DOMAIN_NAME}.my.salesforce.com`,
      `https://${SALESFORCE_DOMAIN_NAME}.my.site.com/`,
      gslbBaseURL,
      `${SALESFORCE_ORG_ID}`,
      `${SALESFORCE_LIVE_AGENT_API_NAME}`,
      {
        baseLiveAgentContentURL: "https://c.la2-c1cs-ukb.salesforceliveagent.com/content",
        deploymentId: `${SALESFORCE_CHAT_DEPLOYMENT_ID}`,
        buttonId: `${SALESFORCE_CHAT_BUTTON_ID}`,
        baseLiveAgentURL: "https://d.la2-c1cs-ukb.salesforceliveagent.com/chat",
        eswLiveAgentDevName: `${SALESFORCE_LIVE_AGENT_API_NAME}`,
        isOfflineSupportEnabled: true,
      },
    );
  };

  const open = () => {
    if (window.embedded_svc) {
      window.embedded_svc.bootstrapEmbeddedService();
    }
  };

  useEffect(() => {
    if (!window.embedded_svc) {
      const s = document.createElement("script");
      s.setAttribute("src", `https://${SALESFORCE_DOMAIN_NAME}.my.salesforce.com/embeddedservice/5.0/esw.min.js`);
      s.onload = () => {
        initESW(null);
      };
      document.body.appendChild(s);
    } else {
      initESW("https://service.force.com");
    }

    helpButtonLoaded();
  }, []);

  return (
    <LiveChatContext.Provider value={{ isLoaded, open, buttonText }}>
      { children }
    </LiveChatContext.Provider>
  );
};

export const useLiveChatContext = () => useContext(LiveChatContext);
