import React, { createContext, ReactNode, useContext } from "react";
import {
  useMutation, useQuery, UseQueryResult,
} from "react-query";
import { SearchClient } from "algoliasearch";
import {
  Index, CategorizedSearchResult, SearchService, SearchResult, SearchOptions, HitRecord,
} from "../services/search.service";

export type Params = { input: string; index: string; searchOptions?: Partial<SearchOptions> };

type SearchContext = {
  client: SearchClient;
  search: (params: Params) => Promise<SearchResult>;
  useSearchQuery: <T extends HitRecord>(params: Params) => UseQueryResult<SearchResult<T>, Error>;
  categorizedSearch: (params:Params) => Promise<CategorizedSearchResult>;
  indexes: typeof Index;
};

const SearchContext = createContext<SearchContext | null>(
  null,
);

export type SearchContextProviderProps = {
  children: ReactNode;
};

export const SearchContextProvider = (
  props: SearchContextProviderProps,
) => {
  const { children } = props;
  const searchService = new SearchService();

  const asyncSearch = useMutation<SearchResult, Error, {
    input: string;
    index: string;
    searchOptions?: Partial<SearchOptions>;
  }>(
    ({ input, index, searchOptions }) => searchService.search(input, index, searchOptions),
  );

  const search = (params: Params) => asyncSearch.mutateAsync(params);

  const asyncCategorizedSearch = useMutation<CategorizedSearchResult, Error, Params>(
    (params) => searchService.categorizedSearch(params.input, params.index, params.searchOptions),
  );

  const categorizedSearch = (params: Params) => asyncCategorizedSearch.mutateAsync(params);

  const useSearchQuery = <T extends HitRecord>(
    { input, index, searchOptions }: Params,
  ) => useQuery<SearchResult<T>, Error>(
    [input, index, searchOptions],
    () => searchService.search<T>(input, index, searchOptions),
  );

  return (
    <SearchContext.Provider
      value={{
        client: searchService.client,
        search,
        categorizedSearch,
        indexes: Index,
        useSearchQuery,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export const useSearchContext = () => {
  const searchContext = useContext(SearchContext);
  if (!searchContext) {
    throw new Error(
      "Can’t find search context, nest the component under SearchContextProvider",
    );
  }

  return searchContext;
};
